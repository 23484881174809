<template>
  <v-container class="loading-time">

    <h3>Temps de chargement</h3>
    <h4>Référence</h4>

    <p>
      Permet de calculer le temps passé pour charger une tonne de produit en fonction d'un précédent chargement. 
    </p>

    <v-row>
      <v-col md="4">
        <v-text-field v-model.number="refLoad" label="Poids chargé" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field v-model.number="refTimeMinutes" label="Durée chargement (min)" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field v-model.number="refTimeSeconds" label="Durée chargement (secondes)" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-alert type="info" dense style="margin-top: 10px;">
      {{refRate.toFixed(2) || 0}} secondes/tonne
    </v-alert>

    <h4>Temps</h4>

    <p>
      Permet de calculer le temps de chargement en fonction de la référence calculée précédemment.
    </p>

    <v-row>
      <v-col md="4">
        <v-text-field v-model.number="targetTareWeight" label="Tare" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field v-model.number="targetTotalWeight" label="Poids brut (cible)" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field v-model.number="targetLoadWeight" label="Poids net" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-alert type="info" dense style="margin-top: 10px;">
      {{targetTimeMinutes || 0}} minutes et {{targetTimeSeconds || 0}} secondes
    </v-alert>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      //rate: 0,
      refLoad: 0,
      refTimeMinutes: 0,
      refTimeSeconds: 0,
      //targetLoadWeight: 30,
      targetTareWeight: 14,
      //targetTimeMinutes: 0,
      //targetTimeSeconds: 0,
      targetTotalWeight: 44
    };
  },
  
  computed: {
    refRate: function() {
      let refTime = this.refTimeMinutes * 60 + this.refTimeSeconds;
      //this.rate = refTime / this.refLoad;
      if (this.refLoad === 0) return 0;
      return refTime / this.refLoad; // seconds/ton
    },
    targetLoadWeight: {
      get: function() {
        return this.targetTotalWeight - this.targetTareWeight;
      },
      set: function(newVal) {
        if (this.targetTotalWeight) this.targetTareWeight = this.targetTotalWeight - newVal;
        if (this.targetTareWeight) this.targetTotalWeight = this.targetTareWeight + newVal;
      }
    },
    targetTimeMinutes: function() {
      let loadTimeSecond = this.targetLoadWeight * this.refRate;
      return Math.trunc(loadTimeSecond / 60);
    },
    targetTimeSeconds: function() {
      let loadTimeSecond = this.targetLoadWeight * this.refRate;
      if (loadTimeSecond === 0) return 0;
      return (loadTimeSecond - Math.trunc(loadTimeSecond / 60) * 60).toFixed(0);
    }
  }
};
</script>