<template>
  <v-container class="separator">

    <h3>Réglages séparateur</h3>

    <v-form>
      <v-row>
        <v-col>
          <v-select
            v-model="selectedNumberOfGrills"
            hide-details
            :items="numberOfGrillsOptions"
            item-text="name"
            item-value="id"
            label="Nombre de grilles"
            @change="clearSelectedConfig"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="selectedCereal"
            hide-details
            :items="cerealOptions"
            item-text="name"
            item-value="id"
            label="Produit"
            @change="clearSelectedConfig"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="selectedOperation"
            hide-details
            :items="operationOptions"
            item-text="name"
            item-value="id"
            label="Type d'opération"
            @change="clearSelectedConfig"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="filteredConfigs.length" class="py-3">
      <h3>Configurations disponibles</h3>
      <v-select
        v-model="selectedConfigId"
        hide-details
        :items="filteredConfigs"
        item-text="description"
        item-value="_id"
        label="Sélectionner une configuration"
      ></v-select>
    </div>
    <v-alert v-else type="warning" class="mt-5">
      Aucune configuration trouvée
    </v-alert>

    <v-card
      v-if="selectedConfigId"
      color="#eee"
      class="mb-2"
    >
      <v-toolbar
        color="info"
        dense
        flat
      >
        <v-toolbar-title class="white--text">Détails de la configuration</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-simple-table
          class="transparent-table"
          dense
        >
          <tr>
            <td>Nombre de grilles</td>
            <td>{{ selectedConfig.numberOfGrills }}</td>
          </tr>
          <tr>
            <td>Produit</td>
            <td>{{ getCerealName(selectedConfig.cereal) }}</td>
          </tr>
          <tr>
            <td>Opération</td>
            <td>{{ getOperationName(selectedConfig.operation) }}</td>
          </tr>
          <tr>
            <td>Vitesse recommandée</td>
            <td>{{ selectedConfig.speed }} tr/min</td>
          </tr>
          <tr>
            <td>Inclinaison recommandée</td>
            <td>{{ selectedConfig.slope }}°</td>
          </tr>

        </v-simple-table>
      </v-card-text>

      <v-card-text class="pa-0">
        <v-row justify="center" dense style="text-align: center;">
          <v-col
            v-for="(grill, index) in selectedConfig.grillSet"
            :key="index"
            cols="auto"
          >
            <img :src="getGrillImage(grill)" :alt="'Grille ' + getGrillSize(grill)" height="150" />
            <p class="mb-0">{{ getGrillSize(grill) }}</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="editConfig" :disabled="selectedConfig.default">Modifier</v-btn>
        <v-btn color="error" @click="deleteConfig" :disabled="selectedConfig.default">Supprimer</v-btn>
      </v-card-actions>

    </v-card>

    <div>
      <v-btn color="primary" @click="showAddConfigDialog">Ajouter une nouvelle configuration</v-btn>
    </div>

    <v-dialog v-model="isDialogOpen">
      <v-card>
        <v-card-title>{{ editMode ? 'Modifier une configuration' : 'Ajouter une configuration' }}</v-card-title>
        <v-card-text>
          <v-form ref="configForm">
            <v-select
              v-model="form.numberOfGrills"
              :items="numberOfGrillsOptions"
              item-text="name"
              item-value="id"
              label="Nombre de grilles"
            ></v-select>
            <v-select
              v-model="form.cereal"
              :items="cerealOptions"
              item-text="name"
              item-value="id"
              label="Produit"
            ></v-select>
            <v-select
              v-model="form.operation"
              :items="operationOptions"
              item-text="name"
              item-value="id"
              label="Opération"
            ></v-select>
            <v-text-field
              v-model="form.speed"
              label="Vitesse"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.slope"
              label="Inclinaison"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.description"
              label="Description"
            ></v-text-field>
            <v-select
              v-model="form.grillSet"
              chips
              deletable-chips
              :items="grillOptions"
              item-text="name"
              item-value="id"
              label="Jeu de grilles"
              multiple
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isDialogOpen = false">Annuler</v-btn>
          <v-btn color="primary" @click="saveConfig">{{ editMode ? 'Enregistrer' : 'Ajouter' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import PouchDB from 'pouchdb';

export default {
  data: function() {
    return {
      selectedNumberOfGrills: null,
      selectedCereal: null,
      selectedOperation: null,
      selectedConfigId: null,
      numberOfGrillsOptions: [
        { id: 2, name: '2 grilles' },
        { id: 3, name: '3 grilles' },
        { id: 4, name: '4 grilles' },
        { id: 5, name: '5 grilles' },
      ],
      cerealOptions: [
        { id: 'BLE', name: 'Blé tendre' },
        { id: 'BLE_DUR', name: 'Blé dur' },
        { id: 'COLZA', name: 'Colza' },
        { id: 'FEVEROLE', name: 'Féverole' },
        { id: 'LIN', name: 'Lin' },
        { id: 'MAIS', name: 'Maïs' },
        { id: 'ORGE', name: 'Orge' },
        { id: 'POIS', name: 'Pois' },
        { id: 'SORGHO', name: 'Sorgho' },
        { id: 'TOURNESOL', name: 'Tournesol' },
      ],
      operationOptions: [
        { id: 'PRE_CLEANING', name: 'Pré-nettoyage' },
        { id: 'CLEANING', name: 'Nettoyage' },
        { id: 'CALIBRATING', name: 'Calibrage' },
        { id: 'SEPARATING', name: 'Séparation' },
      ],
      grillOptions: [
        { id: 'GRILL_1_1_20', name: '1.1x20', image: '/images/grille_oblong.jpg' },
        { id: 'GRILL_2_1_20', name: '2.1x20', image: '/images/grille_oblong.jpg' },
        { id: 'GRILL_2_5_20', name: '2.5x20', image: '/images/grille_oblong.jpg' },
        { id: 'GRILL_2', name: '2', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_3', name: '3', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_3_5', name: '3.5', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_4', name: '4', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_4_5', name: '4.5', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_5', name: '5', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_5_5', name: '5.5', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_6', name: '6', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_7', name: '7', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_8', name: '8', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_9', name: '9', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_10', name: '10', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_11', name: '11', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_12', name: '12', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_13', name: '13', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_14', name: '14', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_15', name: '15', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_16', name: '16', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_17', name: '17', image: '/images/grille_rond.jpg' },
        { id: 'GRILL_18', name: '18', image: '/images/grille_rond.jpg' },
      ],
      configs: [],

      isDialogOpen: false,
      editMode: false,
      form: {
        _id: null,
        numberOfGrills: null,
        cereal: null,
        operation: null,
        speed: null,
        slope: null,
        description: '',
        grillSet: [],
      },
      db: new PouchDB('ta_tools_separator'),
      remoteDb: new PouchDB('https://couchdb.dinacel.com/ta_tools_separator'), // CouchDB URL
    };
  },

  computed: {
    filteredConfigs() {
      return this.configs.filter(
        (config) =>
          config.numberOfGrills === this.selectedNumberOfGrills &&
          config.cereal === this.selectedCereal &&
          config.operation === this.selectedOperation
      );
    },
    selectedConfig() {
      return this.configs.find((config) => config._id === this.selectedConfigId);
    },
  },

  watch: {
    selectedConfig(newConfig) {
      if (newConfig) {
        Object.assign(this.form, newConfig);
      }
    },
    filteredConfigs() {
      this.clearSelectedConfig();
    }
  },

  mounted() {
    // initialize configuration list
    this.fetchConfigs();

    // sync with remote couchdb
    this.db.sync(this.remoteDb, {
      live: true,
      retry: true
    }).on('change', this.handleSyncChange)
      .on('paused', this.handleSyncPaused)
      .on('active', this.handleSyncActive)
      .on('denied', this.handleSyncDenied)
      .on('complete', this.handleSyncComplete)
      .on('error', this.handleSyncError);
  },

  methods: {
    async fetchConfigs() {
      const allConfigs = await this.db.allDocs({ include_docs: true });
      console.log('fetchAll:', allConfigs);
      this.configs = allConfigs.rows.map(row => row.doc);
    },
    showAddConfigDialog() {
      this.resetForm();
      this.editMode = false;
      this.isDialogOpen = true;
    },
    editConfig() {
      Object.assign(this.form, this.selectedConfig);
      this.editMode = true;
      this.isDialogOpen = true;
    },
    async deleteConfig() {
      if (this.selectedConfig.default) return;
      await this.db.remove(this.selectedConfig);
      this.fetchConfigs();
      this.selectedConfigId = null;
    },
    resetForm() {
      this.form = {
        id: null,
        numberOfGrills: null,
        cereal: null,
        operation: null,
        speed: null,
        slope: null,
        description: '',
        grillSet: [],
      };
    },
    async saveConfig() {
      const newConfig = { ...this.form };
      if (this.editMode) {
        await this.db.put(newConfig);
      } else {
        newConfig._id = new Date().toISOString();
        await this.db.post(newConfig);
      }
      console.log('save:', newConfig);
      this.fetchConfigs();
      this.isDialogOpen = false;
    },

    getGrillImage(grillId) {
      const grill = this.grillOptions.find((g) => g.id === grillId);
      return grill ? grill.image : '';
    },
    getGrillSize(grillId) {
      const grill = this.grillOptions.find((g) => g.id === grillId);
      return grill ? grill.name : '';
    },
    getCerealName(id) {
      const cereal = this.cerealOptions.find((c) => c.id === id);
      return cereal ? cereal.name : '';
    },
    getOperationName(id) {
      const operation = this.operationOptions.find((o) => o.id === id);
      return operation ? operation.name : '';
    },

    clearSelectedConfig() {
      console.log('clearSelectedConfig');
      this.selectedConfigId = null;
    },
    updateSelectedConfig(val) {
      console.log('updateSelectedConfig', val);
      this.selectedConfigId = val;
    },

    handleSyncChange(info) {
      console.log('sync change', info);
      this.fetchConfigs();
    },
    handleSyncPaused(err) {
      console.log('sync paused', err);
    },
    handleSyncActive() {
      console.log('sync active');
    },
    handleSyncDenied(err) {
      console.log('sync denied', err);
    },
    handleSyncComplete(info) {
      console.log('sync complete', info);
    },
    handleSyncError(err) {
      console.error('sync error', err);
    }
  }
};
</script>

<style scoped>
.transparent-table {
  background-color: transparent !important;
}
</style>