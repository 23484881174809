const datetime = {

  toIsoStringWithTZ: function(datetime, tzOpt, milliOpt) {
    let date = null;
    if (datetime === null)
      date = new Date(); // now
    else
      date = new Date(datetime);

    const tzo = -date.getTimezoneOffset();
    const sign = tzo >= 0 ? '+' : '-';

    const pad = function(num) {
      let norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    const pad3digits = function(num) {
      if (num > 99) return num;
      if (num < 10) return '00' + num;
      return '0' + num;
    };

    let ms = '';
    if (typeof milliOpt !== 'undefined' && milliOpt == true)
      ms = '.' + pad3digits(date.getMilliseconds());

    let tz = '';
    if (typeof tzOpt !== 'undefined' && tzOpt == true)
      tz = sign + pad(tzo / 60) + ':' + pad(tzo % 60);

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      ms + tz;
  },

  /**
   * Format an ISO8601 string in a UI friendly string
   * ie. 2020-11-02 02:49:36Z      to 2020/11/02 03:49:36 (if local time is GMT+1)
   *     2020-11-02T02:49:36+00:00 to 2020/11/02 03:49:36 (if local time is GMT+1)
   * @param {string} datetime 
   */
  formatToLocal: function(datetime) {
    let date = null;
    if (datetime === null)
      date = new Date(); // now
    else
      date = new Date(datetime);

    // @todo refactor: same method in toIsoStringWithTZ()
    const pad = function(num) {
      let norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    return pad(date.getDate()) +
      '/' + pad(date.getMonth() + 1) +
      '/' + date.getFullYear() +
      ' ' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds())
   },

  /**
   * Format an ISO8601 string in a 'datetime-local' compatible string
   * ie. 2020-11-02 02:49:36Z      to 2020-11-02T03:49:36 (if local time is GMT+1)
   *     2020-11-02T02:49:36+00:00 to 2020-11-02T03:49:36 (if local time is GMT+1)
   * @param {string} datetime 
   */
  formatToDatetimeLocal: function(datetime) {
    return this.toIsoStringWithTZ(datetime, false, false);
  },

  /**
   * Format a string from a 'datetime-local' input
   * to an ISO8601 string without delimiter
   * @param {string} date 
   */
  updateFromDatetimeLocal: function(date) {
    // date with "T"
    const dateArray = date.split('T');
    this.editedItem.measure_time = dateArray[0] + ' ' + dateArray[1];
  },

  /**
   * Returns the current date and time in a 'datetime-local'
   * compatible string
   */
  currentDatetime: function() {
    const now = new Date();
    const utcString = now.toISOString().substring(0,19);
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hour = now.getHours();
    const minute = now.getMinutes();
    //const second = now.getSeconds();
    const localDatetime = year + '-' +
      (month < 10 ? '0' + month.toString() : month) + '-' +
      (day < 10 ? '0' + day.toString() : day) + 'T' +
      (hour < 10 ? '0' + hour.toString() : hour) + ':' +
      (minute < 10 ? '0' + minute.toString() : minute) +
      utcString.substring(16,19);
    return localDatetime;
  }
}

export default datetime;