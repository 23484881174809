<template>
  <v-container class="sica-webcam" fluid>

    <h3>Webcam SICA</h3>

    <v-row fill-height>
      <v-col cols="12" md="6" fill-height>
        <v-card outlined>
          <v-img
            :src="baseURL + '/uploads/sica-webcam/webcam1.jpg?cache=' + cacheKey"
            class="fill-height white--text align-end"
            gradient="to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 80%, rgba(0,0,0,.7) 100%"
          >
            <v-card-title>Entrée principale</v-card-title>
          </v-img>

          <!--<v-carousel
            v-model="carousel"
            :continuous="false"
            :cycle="false"
            fill-height
            hide-delimiters
            :show-arrows="false"
            :touchless="true"
          >
            <v-carousel-item
              v-for="(item,i) in items"
              :key="i"
              :src="item.src"
              class="white--text align-end"
              contain
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>-->
        </v-card>
      </v-col>

      <v-col cols="12" md="6" fill-height>
        <v-card outlined>
          <v-img
            :src="baseURL + '/uploads/sica-webcam/webcam2.jpg?cache=' + cacheKey"
            class="fill-height white--text align-end"
            gradient="to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 80%, rgba(0,0,0,.7) 100%"
          >
            <v-card-title>Attente pesage</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      baseURL: 'https://tools.terreatlantique.com',
      cacheKey: +new Date(),

      items: [
        {
          src: 'https://tools.terreatlantique.com/uploads/sica-webcam/webcam1.jpg?cache=' + +new Date()
        }
      ],
      carousel: 0
    };
  },

  created() {
    this.interval = setInterval(() => {
      this.cacheKey = +new Date();
      // carousel
      /*this.items.push({
        src: 'https://tools.terreatlantique.com/uploads/sica-webcam/webcam1.jpg?cache=' + this.cacheKey
      });
      this.carousel++; // show next slide*/
    }, 10 * 1000);
  },

  destroyed() {
    clearInterval(this.interval);
  }
};
</script>