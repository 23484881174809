<template>
  <v-container class="blending">

    <h3>Mélange</h3>

    Les différentes caractéristiques réagissent de manière proportionnelle aux mélanges, sauf le temps de chute de Hagberg qui suit un calcul complexe (cet outil en tient compte).

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Caractéristiques</th>
            <th class="text-left">
              Lot A
            </th>
            <th class="text-left">
              Lot B
            </th>
            <th class="text-left">
              Mélange
            </th>
            <th class="text-left">
              Unités
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td><strong>Poids brut</strong></td>
            <td>
              <v-text-field v-model.number="partA"
                @focus="$event.target.select()"
                autofocus
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="partB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{partC}}</td>
            <td>tonnes</td>
          </tr>

          <tr>
            <td>Humidité</td>
            <td>
              <v-text-field v-model.number="humidityA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="humidityB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{humidityC || 0}}</td>
            <td>%</td>
          </tr>

          <tr>
            <td>Poids spécifique</td>
            <td>
              <v-text-field v-model.number="specificWeightA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="specificWeightB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{specificWeightC || 0}}</td>
            <td>kg/hL</td>
          </tr>

          <tr>
            <td>Impuretés</td>
            <td>
              <v-text-field v-model.number="impuritiesA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="impuritiesB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{impuritiesC || 0}}</td>
            <td>%</td>
          </tr>

          <tr>
            <td>Taux de protéines</td>
            <td>
              <v-text-field v-model.number="proteinsA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="proteinsB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{proteinsC || 0}}</td>
            <td>%</td>
          </tr>

          <tr>
            <td>Calibrage</td>
            <td>
              <v-text-field v-model.number="sizeA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="sizeB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{sizeC || 0}}</td>
            <td>%</td>
          </tr>

          <tr>
            <td>Temps de chute (Hagberg)</td>
            <td>
              <v-text-field v-model.number="fallingNumberA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="fallingNumberB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{fallingNumberC || 0}}</td>
            <td>secondes</td>
          </tr>

          <tr>
            <td>Ergots</td>
            <td>
              <v-text-field v-model.number="ergotsA"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="ergotsB"
                @focus="$event.target.select()"
                dense
                type="number"
              ></v-text-field>
            </td>
            <td>{{ergotsC || 0}}</td>
            <td>unité/kg</td>
          </tr>

        </tbody>
      </template>
    </v-simple-table>

  </v-container>
</template>

<script>
//import Blending from '@/components/Blending.vue';

export default {
  name: 'Blending',

  /*components: {
    Blending
  }*/

  data: function() {
    return {
      partA: 0,
      humidityA: 0,
      specificWeightA: 0,
      impuritiesA: 0,
      proteinsA: 0,
      sizeA: 0,
      fallingNumberA: 0,
      ergotsA: 0,

      partB: 0,
      humidityB: 0,
      specificWeightB: 0,
      impuritiesB: 0,
      proteinsB: 0,
      sizeB: 0,
      fallingNumberB: 0,
      ergotsB: 0
    };
  },
  
  computed: {
    partC: function() {
      return this.partA + this.partB;
    },
    humidityC: function() {
      return ((this.partA * this.humidityA + this.partB * this.humidityB) / (this.partA + this.partB)).toFixed(1);
    },
    specificWeightC: function() {
      return ((this.partA * this.specificWeightA + this.partB * this.specificWeightB) / (this.partA + this.partB)).toFixed(1);
    },
    impuritiesC: function() {
      return ((this.partA * this.impuritiesA + this.partB * this.impuritiesB) / (this.partA + this.partB)).toFixed(1);
    },
    proteinsC: function() {
      return ((this.partA * this.proteinsA + this.partB * this.proteinsB) / (this.partA + this.partB)).toFixed(1);
    },
    sizeC: function() {
      return ((this.partA * this.sizeA + this.partB * this.sizeB) / (this.partA + this.partB)).toFixed(1);
    },
    fallingNumberC: function() {
      let percentA = 100 * this.partA / (this.partA + this.partB);
      let percentB = 100 * this.partB / (this.partA + this.partB);

      let lnA = 6000 / ( this.fallingNumberA - 50 );
      let lnB = 6000 / ( this.fallingNumberB - 50 );

      let lnT = (percentA * lnA + percentB * lnB) / 100;

      return ((6000 / lnT) + 50).toFixed(0);
    },
    ergotsC: function() {
      return ((this.partA * this.ergotsA + this.partB * this.ergotsB) / (this.partA + this.partB)).toFixed(1);
    }
  }
};
</script>

<style scoped>
.v-data-table td:nth-child(1) {
  background-color: #acacac;
}
.v-data-table td:nth-child(2) {
  background-color: #fff782;
}
.v-data-table td:nth-child(3) {
  background-color: #afcfff;
}
.v-data-table td:nth-child(4) {
  background-color: #a9ffca;
}
</style>