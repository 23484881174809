import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import BarcodeConfig from '@/views/BarcodeConfig.vue';
import BarcodeParser from '@/views/BarcodeParser.vue';
import Blending from '@/views/Blending.vue';
import Insecticide from '@/views/Insecticide.vue';
import LoadingTime from '@/views/LoadingTime.vue';
import Percent from '@/views/Percent.vue';
import Separator from '@/views/Separator.vue';
import SICAWebcam from '@/views/SICAWebcam.vue';
import WeightScale from '@/views/WeightScale.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/loading-time',
    name: 'Temps de chargement',
    component: LoadingTime
  },
  {
    path: '/weight-scale',
    name: 'Pont-bascule',
    component: WeightScale
  },
  {
    path: '/percent',
    name: 'Impuretés/calibrage',
    component: Percent
  },
  {
    path: '/insecticide',
    name: 'Insecticide',
    component: Insecticide
  },
  {
    path: '/separator',
    name: 'Séparateur',
    component: Separator
  },
  {
    path: '/blending',
    name: 'Mélanges',
    component: Blending
  },
  {
    path: '/sica-webcam',
    name: 'Webcam SICA',
    component: SICAWebcam
  },
  {
    path: '/barcode-config',
    name: 'Configuration douchette',
    component: BarcodeConfig
  },
  {
    path: '/barcode-parser',
    name: 'Analyseur douchette',
    component: BarcodeParser
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;