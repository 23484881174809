<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-clock-outline</v-icon>
            <a href="/#/loading-time" class="text-decoration-none">Temps de chargement</a>
          </v-card-title>
          <v-card-text>Permet de calculer le temps passé pour charger une tonne de produit</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-weight-kilogram</v-icon>
            <a href="/#/weight-scale" class="text-decoration-none">Pont-bascule</a>
          </v-card-title>
          <v-card-text>Permet d'afficher la poids mesuré par un pont-bascule en temps réel</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-vacuum</v-icon>
            <a href="/#/percent" class="text-decoration-none">Impuretés/calibrage</a>
          </v-card-title>
          <v-card-text>Permet de calculer le pourcentage massique de la fraction d'un échantillon</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-beaker-outline</v-icon>
            <a href="/#/insecticide" class="text-decoration-none">Insecticide</a>
          </v-card-title>
          <v-card-text>Permet de calculer le débit d'une pompe à insecticide</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-filter-cog</v-icon>
            <a href="/#/separator" class="text-decoration-none">Séparateur</a>
          </v-card-title>
          <v-card-text>Aide au choix des grilles de séparateur (en construction...)</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-blender</v-icon>
            <a href="/#/blending" class="text-decoration-none">Mélanges</a>
          </v-card-title>
          <v-card-text>Détermine les caractéristiques finales d'un mélange de 2 lots différents</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
            <v-icon left>mdi-cctv</v-icon>
            <a href="/#/sica-webcam" class="text-decoration-none">Webcam SICA</a>
          </v-card-title>
          <v-card-text>Voir les images des caméras de la SICA.</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home'
};
</script>