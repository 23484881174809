<template>
  <v-toolbar height="auto">
    <v-app-bar-nav-icon
      @click="drawerState = !drawerState"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>Outils</v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- snackbar -->
    <v-snackbar v-model="loadingSnackbar" timeout="1500" app bottom right>
      {{snackbarText}}
      <template v-slot:action="{ attrs }">
        <v-btn
          @click="loadingSnackbar = false"
          color="blue"
          text
          v-bind="attrs"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-toolbar>
</template>

<script>
import { useBaseStore } from '@/stores/base';

export default {
  setup() {
    const baseStore = useBaseStore();
    return {
      baseStore
    };
  },

  data: function() {
    return {
      loadingSnackbar: false,
      snackbarText: 'Chargement...'
    }
  },

  computed: {
    drawerState: {
      get: function() { return this.baseStore.getDrawerState; },
      set: function(v) { return this.baseStore.toggleDrawerState(v); },
    }
  }
};
</script>

<style scopes>
div.v-snack:not(.v-snack--absolute) {
  height: calc(100vh - 56px); /* 56px = v-app-bar height */
}
</style>