<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :class="`ma-2 animate-${status.color}`"
        variant="text"
        icon
        :color="status.color"
      >
        <v-icon>{{ status.icon }}</v-icon>
      </v-btn>
    </template>
    <span v-if="status.latestMsgDatetime">[{{ status.latestMsgDatetime }}] </span><span>{{ status.msg }}</span>
  </v-tooltip>
</template>

<script setup>
import datetime from '@/utils/date';
import logger from '@/utils/logger';
import { useMqttStore } from '@/stores/mqtt';
import { mdiAlertCircleOutline, mdiLanConnect, mdiLanDisconnect, mdiLanPending } from '@mdi/js';
import { computed, onUnmounted, ref } from 'vue';

const mqttStore = useMqttStore();

// data
const interval = ref(null);
const ticker = ref(Date.now());

// create ticker
// @see force recomputing status() on tick
interval.value = setInterval(() => {
  ticker.value = Date.now();
  //logger.log('tick');
}, 2000);

// unmounted
// @todo ??? beforeRouteLeave(to, from) {
onUnmounted(() => {
  // clear ticker
  clearInterval(interval.value);
});

// computed
const status = computed(() => {
  // force recomputing on tick
  // @see created() and destroyed()
  ticker.value; // just referencing it is enough!

  // @todo better status definition!

  /**
   * remote plc status
   * remote pc status
   * remote script (on pc) status
   * internet status: remote device to internet
   * internet status: local browser to internet
   * broker: remote device to broker
   * broker: local browser to broker
   * broker status
   */

  // check local browser mqtt connection
  //   - browser is not connected to the internet (no internet, firewall...)
  //   - mqtt broker is down
  //   - mqtt broker refused the connection (invalid identifiers, rate limits, firewall...)
  const connectionStatus = mqttStore.connectionStatus;

  // get latest message timestamp
  const latestMsgTimestamp = mqttStore.topicData.time;

  let latestMsgDatetime = null; // human readable
  let timeSinceLatestMsg = null;

  if (typeof latestMsgTimestamp !== 'undefined') {
    latestMsgDatetime = datetime.formatToLocal(latestMsgTimestamp);
    const latestMsgDate = new Date(latestMsgTimestamp);
    const currentDate = new Date();

    timeSinceLatestMsg = Math.floor((currentDate - latestMsgDate) / 1000);
    logger.log('Time diff with msg timestamp:', timeSinceLatestMsg);
  } // else: no MQTT message received: empty topic? broker down? no connection?

  // check local browser mqtt connection
  let color = 'error';
  let icon = mdiLanDisconnect;
  let msg = 'Erreur de connexion';

  if (connectionStatus === 'connected') {
    color = 'success';
    icon = mdiLanConnect;
    msg = 'Connecté';

    // local browser mqtt connection is up: check remote side status

    // -> checking remote device lwt
    // @todo
    const remoteClientStatus = mqttStore.remoteClientStatus; // @todo defined by the retained message received on the lwt topic
    if (remoteClientStatus === 'online') {
      // remote site: internet is up AND the script is running AND the computer is powered on
      if (timeSinceLatestMsg > 300) { // @todo should be 300 because message are posted only if data have changed (not each 10s)
        color = 'warning';
        icon = mdiLanConnect;
        msg = 'Connecté mais le site distant rencontre un problème (KEPServer planté/arrêté, automate planté/éteint, connexion PC-automate coupée)';
      }
    } else if (remoteClientStatus === 'offline') {
      // remote site: internet is down OR the script is crashed OR the computer is down
      color = 'warning';
      icon = mdiLanConnect;
      msg = 'Connecté mais le site distant rencontre un problème (coupure réseau/électricité, PC planté ou éteint)';
    } else {
      // wtf?
      color = 'warning';
      icon = mdiAlertCircleOutline;
      //msg = 'Connecté mais le site distant rencontre un problème innatendu...';
      msg = 'Connecté mais le site distant n\'est pas encore compatible';
    }

    // -> checking remote devices/connection
    // A new message is posted by the script whenever the OPC data changes, so if they haven't changed for too long,
    // it means either the script has crashed, the remote site's internet connection is down, or the OPC server is down.
    // With 'remoteClientStatus' we can know the status of the remote site's client: its internet is down,
    // the script is crashed or the computer is down.
    if (timeSinceLatestMsg > 300) {
      // opc connection lost
      //   - pc to plc network down
      //   - plc down
      //   - kep server services down
      //logger.log('Too much time difference: connection to OPC was lost?');

      //logger.log('Browser connected to the broker, so no data received from distant site');
      // check the reason
      //   - if lwt 'online' => opc connection lost (see reasons on the previous comment block)
      //   - if lwt 'offline' => internet down on distant site or computer down (or kep server down? => script can send messages to broker without live opc connection?)
    }
  } else if (connectionStatus === 'reconnecting') {
    color = 'warning';
    icon = mdiLanPending;
    msg = 'Reconnexion...';

    // because we're not connected to the internet or to the broker:
    //   remote-device-to-opc connection undefined
    //   remote-device-script script running undefined
    //   remote-device-to-internet connection undefined
    // 
  } else if (connectionStatus === 'error') {
    color = 'error';
    icon = mdiLanDisconnect;
    msg = 'Erreur de connexion';

    // because we're not connected to the internet or to the broker:
    //   remote-device-to-opc connection undefined
    //   remote-device-script script running undefined
    //   remote-device-to-internet connection undefined
  } else {
    // events:
    // - disconnect: after receiving disconnect packet from broker (mqtt5 only)
    // - offline: when client goes offline
    // - close: after a disconnection
    color = 'error';
    icon = mdiLanDisconnect;
    msg = 'Non-connecté';

    // because we're not connected to the internet or to the broker:
    //   remote-device-to-opc connection undefined
    //   remote-device-script script running undefined
    //   remote-device-to-internet connection undefined
  }

  return {
    latestMsgDatetime: latestMsgDatetime,
    msg: msg,
    color: color,
    icon: icon
  };
});
</script>

<style scoped>
.v-btn.animate-warning .v-icon {
  animation: blink 1.25s linear infinite; /* linear -> steps(1, end) */
}
.v-btn.animate-error .v-icon {
  animation: blink 0.75s steps(1, end) infinite; /* linear -> steps(1, end) */
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>