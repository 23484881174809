<template>
  <v-container class="scanner-unloader">
    <h3>Analyseur décharge douchette</h3>

    <v-btn-toggle
      v-model="separator"
      dense
      mandatory
    >
      <v-btn small value="gs">&lt;GS&gt;</v-btn>
      <v-btn small value="at">@</v-btn>
    </v-btn-toggle>

    <br><br>

    <v-data-table
      :headers="headers"
      :item-class="rowClass"
      :items="parsedCodes"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, -1]
      }"
      :items-per-page="50"
      dense
      multi-sort
      class="elevation-1"
    >
      <template v-slot:[`item.batch`]="{ item }">
        <v-icon v-if="!item.batch" color="red">mdi-alert</v-icon>
        {{item.batch}}
      </template>

      <template v-slot:[`item.prod_date`]="{ item }">
        <v-icon v-if="!item.prod_date" color="red">mdi-alert</v-icon>
        {{item.prod_date}}
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Recharger
        </v-btn>
      </template>
    </v-data-table>

    <br>

    <v-row class="text-center">
      <v-col cols="12">
        <v-textarea
          autofocus
          auto-grow
          clearable
          clear-icon="mdi-close-circle"
          dense
          label="Déchargement"
          outlined
          placeholder="..."
          rows="10"
          v-model="content"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-for="element in codes" :key="element.index">
      <BarcodeRow :code="element" :separator="separator" />
    </v-row>
  </v-container>
</template>

<script>
import bark from 'bark-js';
import BarcodeRow from '@/components/BarcodeRow';

export default {
  name: 'HelloWorld',

  components: {
    BarcodeRow
  },

  data: function() {
    return {
      content: '',
      codes: [],
      parsedCodes: [],
      separator: 'at',

      page: 0,
      totalItems: 0,
      totalPages: 0,
      sortBy: 'id',
      sortDesc: false,
      locale: 'fr-FR',
      notifications: [],
      loading: true,
      options: {},

      dialog: false,
      dialogDelete: false,
      headers: [
        /*{ text: 'ID', value: 'id' },
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name'
        },*/
        //{ text: 'Description', value: 'description' },
        { text: 'GTIN UL', value: 'gtin' },
        { text: 'GTIN UC', value: 'gtin2' },
        { text: 'Date de fabrication', value: 'prod_date', sortable: false },
        { text: 'N° de lot', value: 'batch', sortable: false },
        { text: 'N° de série', value: 'sn', sortable: false },
        { text: 'SSCC', value: 'sscc', sortable: false },
        { text: 'Marque', value: 'brand' },
        { text: 'Quantité', value: 'quantity' },
        { text: 'Poids', value: 'weight' },
        //{ text: 'DLC', value: 'dlc' },
        //{ text: 'DLUO', value: 'dluo' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: '',
        description: '',
        channel: 0
      },
      defaultItem: {
        id: 0,
        name: '',
        description: '',
        channel: 0
      }
    };
  },

  watch: {
    content: function(/*newVal, oldVal*/) {
      this.splitLines(this.content);
    },
    separator: function(/*newVal, oldVal*/) {
      this.splitLines(this.content);
    }
  },

  methods: {
    splitLines: function (content) {
      // for each newline/carriage return
      this.codes = content.split(/[\r|\n]/); // @todo \r\n?

      // @todo used by datatable
      let parsedCodes = [];

      for (let i = 0; i < this.codes.length; i++) {
        // remove datamatrix prefix "]D2"
        const cleanCode = this.codes[i].replace(/^\]d[1|2]/i, '');

        // decode
        let parsedCode = null;
        let settings = {};
        if (this.separator === 'at') {
          settings = {
            fnc: '@'
          }
        }

        try {
          parsedCode = bark(cleanCode, settings);

          const output = this.parseElements(parsedCode.elements);
          parsedCodes.push(output);
        } catch(err) {
          console.error(err.message);
        }
      }

      this.parsedCodes = parsedCodes;
      this.loading = false;
      this.totalItems = parsedCodes.length;
      this.totalPages = 1;

      //console.log(this.parsedCodes);
    },

    parseElements: function(elements) {
      let output = {
        gtin: null,
        batch: null,
        prod_date: null
      };

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        switch(element.ai) {
          case '00':
            // SSCC (numéro de palette)
            output.sscc = element.value; // sscc, serial shipping container code?
            break;
          case '01': {
            // GTIN (code EAN) d'un article. UL : unité logistique (unité de regroupement)
            output.gtin = element.value;
            // get brand (by reference)
            this.parseBrand(output, element.value);
            break;
          }
          case '02':
            // GTIN (code EAN) de l'article contenu
            output.gtin2 = element.value; // n° article sur la palette... 01=gtin bis???
            // get brand (by reference)
            this.parseBrand(output, element.value);
            break;
          case '10':
            // numéro de lot
            output.batch = element.value;
            break;
          case '11':
            // date de fabrication
            output.prod_date = element.value;
            break;
          case '15':
            // DLUO
            output.dluo = element.value; // best before date
            break;
          case '17':
            // DLC
            output.dlc = element.value; // ?
            break;
          case '20':
          case '21':
          case '413':
            // variante produit
            // ou serial number (Bayer => RMNR)
            // ou lieu de livraison final
            output.misc = element.value; // ?
            break;
          case '37':
            // quantité contenue
            output.quantity = element.value; // nbr de suremballage sur la palette
            break;
          case '310x':
            // poids net en kg
            output.weight = element.value; // ?
            break;
        }
      }

      // handle missing values
      if ((output.gtin === null && output.gtin2 === null)
        || output.batch === null
        || output.prod_date === null) {
        output.error = true;
      }

      return output;
    },

    parseBrand: function(output, gtin) {
      let m;

      /** GS1 Company prefix : ADAMA FRANCE **/
      if ((m = new RegExp(/^037002491/, 'm').exec(gtin)) !== null) {
        m.forEach((/*match, groupIndex*/) => {
          //console.log(`Found match, group ${groupIndex}: ${match}`);
          // found "adama"
          output.brand = 'ADAMA FRANCE';
        });
      }

      /** GS1 Company prefix : BASF FRANCE SAS **/
      if ((m = new RegExp(/^0349699/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "basf"
          output.brand = 'BASF FRANCE SAS';
        });
      }

      /** GS1 Company prefix : BAYER SAS - France **/
      if ((m = new RegExp(/^0352655/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "bayer"
          output.brand = 'BAYER SAS';
        });
      }

      /** GS1 Company prefix : BELCHIM CROP PROTECTION NV **/
      if ((m = new RegExp(/^05414572/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "belchim"
          output.brand = 'BELCHIM CROP PROTECTION NV';
        });
      }

      /** GS1 Company prefix : Corteva Agriscience **/
      if ((m = new RegExp(/^00194697/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "corteva"
          output.brand = 'Corteva Agriscience';
        });
      }

      /** GS1 Company prefix : DOW AGRO SCIENCES SAS **/
      // 0336213
      if ((m = new RegExp(/^0336213/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "dow"
          output.brand = 'DOW AGRO SCIENCES SAS';
        });
      }

      /** GS1 Company prefix : NUFARM SAS **/
      if ((m = new RegExp(/^0348526/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "nufarm"
          output.brand = 'NUFARM SAS';
        });
      }

      /** GS1 Company prefix : PHILAGRO FRANCE **/
      if ((m = new RegExp(/^03660051/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "philagro"
          output.brand = 'PHILAGRO FRANCE';
        });
      }

      /** GS1 Company prefix : SOJAM SAS **/
      if ((m = new RegExp(/^0336167/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "sojam"
          output.brand = 'SOJAM SAS';
        });
      }

      /** GS1 Company prefix : SUMI AGRO FRANCE **/
      if ((m = new RegExp(/^03661708/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "sumi"
          output.brand = 'SUMI AGRO FRANCE';
        });
      }

      /** GS1 Company prefix : SYNGENTA FRANCE SAS **/
      // 0355091
      // 0360039
      if ((m = new RegExp(/^0355091/, 'm').exec(gtin)) !== null) {
        m.forEach(() => {
          // found "syngenta"
          output.brand = 'SYNGENTA FRANCE SAS';
        });
      }

      // @todo decode other brands... :D
    },

    rowClass: function(item) {
      if (typeof item.error !== 'undefined' && item.error) {
        return 'row-error'; //can also return multiple classes e.g ["orange","disabled"]
      }
    }
  }
};
</script>

<style scoped>
>>>.row-error {
  background-color: #ffc6c6;
}
</style>