<template>
  <v-col cols="12">
    <ul v-for="element in decodedString.elements" :key="element.ai">
      <li>{{element.title}} ({{element.ai}}): {{element.value}}</li>
    </ul>
  </v-col>
</template>

<script>
import bark from 'bark-js';

export default {
  name: 'BarcodeRow',

  props: [
    'code',
    'separator'
  ],

  computed: {
    decodedString: function () {
      // remove datamatrix prefix "]D2"
      const cleanCode = this.code.replace(/^\]d[1|2]/i, '');

      // decode
      let settings = {};
      if (this.separator === 'at') {
        settings = {
          fnc: '@'
        }
      }

      try {
        return bark(cleanCode, settings);
      } catch(err) {
        //console.error(err);
        return {
          elements: [{
            title: 'erreur',
            ai: 'xx',
            value: this.code
          }]
        };
      }
    }
  }
};
</script>