import { defineStore } from 'pinia';

export const useBaseStore = defineStore({
  id: 'base',

  state: () => ({
    context: {
      site: null,
      warehouse: null,
      cell: null
    },
    drawerState: false,
    loadingState: false
  }),

  getters: {
    getContext(state) {
      return state.context;
    },
    getDrawerState(state) {
      return state.drawerState;
    },
    getLoadingState(state) {
      return state.loadingState;
    }
  },

  actions: {
    setContext(data) {
      this.context = data;
    },
    setLoadingState(data) {
      this.loadingState = data;
    },
    toggleDrawerState(data) {
      this.drawerState = data;
    }
  }
});