<template>
  <v-container>
    <h3>Configuration Honeywell 1472g</h3>

    <v-row style="margin-top: 5px;">
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <v-textarea
              auto-grow
              autofocus
              clearable
              hide-details="true"
              outlined
              rows="3"
              name="barcode-text"
              label="Directives de configuration"
              v-model="barcodeText"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              @click="renderBarcode"
              color="primary"
              elevation="2"
            >
              Générer
              <v-icon
                right
                dark
              >
                mdi-cogs
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!--<v-spacer></v-spacer>-->

      <v-col cols="auto">
        <v-hover v-slot="{ hover }">
          <v-sheet
            color="white"
            :elevation="hover ? 12 : 3"
            height="156"
            rounded
            width="156"
            style="position: relative;"
          >
            <img :src="linkSVG" class="ma-1" />

            <div v-if="hover" class="align-self-center text-center hover-img">
              <v-btn
                :href="linkPNG"
                class="mt-9"
                color="primary"
                download="configuration-aztec.png"
              >
                PNG
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                :href="linkSVG"
                class="mt-2"
                color="primary"
                download="configuration-aztec.svg"
              >
                SVG
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-sheet>
        </v-hover>
      </v-col>
    </v-row>

    <!--<canvas id="mycanvas"></canvas>
    <img id="barcode-png" justify="start" />
    <a id="link-png" download="configuration-aztec.png">Téléchargement</a>

    <div id="barcode-svg"></div>
    <a id="link-svg" download="configuration-aztec.svg">Téléchargement</a>-->
  </v-container>
</template>

<script>
// @ is an alias to /src
import bwipjs from 'bwip-js';
import DrawingSVG from '@/libs/bwip-drawing-svg.js';

export default {
  name: 'barcode-config',
  
  data: function() {
    return {
      barcodeText: 'DEFOVR;DEFALT;PAP124;KBDCTY3;KBDCNV1;BATENA3;ALLENA0;IDMENA1;BATDLY1000;PREBK2995C81;VSUFCR;DFMBK30124779999E4021D40F1.',
      linkPNG: 'https://via.placeholder.com/148?text=barcode',
      linkSVG: 'https://via.placeholder.com/148?text=barcode',
    };
  },

  methods: {
    renderBarcode: function() {
      // barcode options
      const opts = {
        bcid:            'azteccode',      // barcode type
        text:            this.barcodeText, // text to encode
        scale:           2,                // 3x scaling factor
        //scaleX:        2,
        //scaleY:        2, // default: scaleX
        //rotate:        'N', // default: N (R, L, I)
        padding:         0,
        //paddingbottom: 0,
        //paddingleft:   0,
        //paddingright:  0,
        //paddingtop:    0,
        //backgroundcolor: 'RRGGBB',
        //height:          50,               // bar height, in millimeters
        //width:           50,
        //includetext:     true,             // show human-readable text
        //textxalign:      'center',         // always good to set this

        //parse: true,
        //parsefnc: true,
        readerinit: true,
        eclevel: 23,
        ecaddchars: 3,
        format: 'full',
        layers: 5, // @todo layers must be set to a value from 1 to 22!
        //raw: true
      };


      // render barcode in canvas
      //let canvas = bwipjs.toCanvas('mycanvas', opts);
      //canvas;
      const canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, opts);
      let url = canvas.toDataURL('image/png');
      //document.getElementById('barcode-png').src = url;
      //document.getElementById('link-png').href = url;
      this.linkPNG = url;

      // render barcode in SVG
      bwipjs.fixupOptions(opts);

      try {
        const svg = bwipjs.render(opts, DrawingSVG(opts, bwipjs.FontLib));
        url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
        //document.getElementById('barcode-svg').innerHTML = svg;
        //document.getElementById('link-svg').href = url;
        this.linkSVG = url;
      } catch(e) {
        console.log(e);
      }
    }
  },

  mounted: function() {
    this.renderBarcode();
  }
};
</script>

<style scoped>
.hover-img {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  top: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>