<template>
  <v-app>
    <v-app-bar
      app
      color="green"
      dark
      hide-on-scroll
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo Terre Atlantique"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="110"
        />

        <v-toolbar-title class="d-none d-sm-flex">Outils</v-toolbar-title>
      </div>
      
      <v-spacer></v-spacer>

      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-{{buttonText}}</v-icon>
      </v-btn>
    </v-app-bar>

    <Navigation/>
    <Main/>

  </v-app>
</template>

<script>
import Main from '@/components/Main';
import Navigation from '@/components/Navigation';

export default {
  name: 'App',

  components: {
    Main,
    Navigation
  },

  computed: {
    scrollbarTheme: function() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    buttonText: function() {
      return !this.$vuetify.theme.dark ? 'weather-night' : 'weather-sunny';
    }
  },

  created: function() {
    //
  },

  methods: {
    toggleTheme: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
}
</script>