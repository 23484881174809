//import * as mqtt from 'mqtt/dist/mqtt'; // mqtt/dist/mqtt.min
import mqtt from 'mqtt';
import logger from '@/utils/logger';

export default class MqttService {
  constructor(updateStatusCallback) {
    logger.log('[MQTT] Connecting to: wss://mqtt.terreatlantique.com:8884');

    this.updateStatusCallback = updateStatusCallback;

    //this.topicSubscribers = {};
    this.topicSubscriber = null;

    // connect
    this.client = mqtt.connect(
      'wss://mqtt.terreatlantique.com:8884',
      {
        clientId: 'tools-frontend' + parseInt(Math.random() * 100000),
        username: 'ta-weight-scales-ro',
        password: 'enJer88VbHY7D%TY',
        protocolId: 'MQTT',
        protocolVersion: 4
      }
    );

    this.connected = new Promise((resolve) => {
      this.client.on('connect', () => {
        logger.log('[MQTT] Connected');
        this.updateStatusCallback('connected');

        resolve();
      });
    });

    //logger.log(this.connected);

    this.client.on('disconnect', () => {
      logger.log('[MQTT] Disconnected');
      this.updateStatusCallback('disconnected');
    });

    this.client.on('reconnect', () => {
      logger.log('[MQTT] Reconnecting...');
      this.updateStatusCallback('reconnecting');
    });

    this.client.on('close', () => {
      logger.log('[MQTT] Closed');
      this.updateStatusCallback('closed');
    });

    this.client.on('offline', () => {
      logger.log('[MQTT] Offline');
      this.updateStatusCallback('offline');
    });

    this.client.on('error', (err) => {
      logger.log('[MQTT] Error:', err);
      this.updateStatusCallback('error');
    });

    this.client.on('message', (topic, message) => {
      logger.log('[MQTT] Message received on:', topic);

      // parse json
      let data = null;

      try {
        data = JSON.parse(message.toString());
      } catch(e) {
        // @todo it could be a status message (lwt "online" or "offline")
        data = message.toString();

        if (data !== 'online' && data !== 'offline') {
          logger.log(e);
          logger.error('[MQTT] Caught a malformed JSON from MQTT ("src/services/MqttService.vue" l.76)');
          return; // discard, we'll try the next message...
        }
      }

      this.topicSubscriber(topic, data);
    });
  }

  async subscribe(topic, callback) {
    await this.connected; // wait for the connection to be established

    logger.log('[MQTT] Subscribing to:', topic);

    this.client.subscribe(topic);
    this.topicSubscriber = callback;
  }

  async unsubscribe(topic) {
    await this.connected; // wait for the connection to be established

    logger.log('[MQTT] Unsubscribing from:', topic);

    this.client.unsubscribe(topic);
    this.topicSubscriber = null;
  }
}
