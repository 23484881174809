<template>
  <v-main>
    <Toolbar/>
    <router-view></router-view>
  </v-main>
</template>

<script>
import Toolbar from '@/components/Toolbar';

export default {
  name: 'main-container',

  components: {
    Toolbar
  }
};
</script>