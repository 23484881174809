<template>
  <v-container class="percent">

    <h3>Proportions</h3>

    <p>
      On pèse une masse de grains (référence), on la passe au nettoyeur ou au tamis et on pèse la partie nettoyée/calibrée.
      Cet outil permet de calculer simplement le pourcentage massique par une règle de proportionnalité.
    </p>

    <h4>Référence</h4>

    <v-row class="my-1">
      <v-col md="4">
        <v-text-field v-model.number="refWeight" label="Poids total" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <h4>Partie nettoyée/calibrée</h4>

    <v-row class="my-1">
      <v-col md="4">
        <v-text-field v-model.number="partWeight" label="Poids" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <h4>Résultat</h4>

    <v-alert type="info" dense style="margin-top: 10px;">
      La part nettoyée/calibrée représente <strong>{{percentPart || 0}}%</strong> du total.
      Le taux d'impureté est de <strong>{{percentWaste || 0}}%</strong>.
    </v-alert>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      refWeight: 100,
      partWeight: 0
    };
  },
  
  computed: {
    percentPart: function() {
      if (this.refWeight === 0) return 0;
      let percent = this.partWeight * 100 / this.refWeight;
      return (Math.round(percent * 10) / 10).toFixed(1);
    },
    percentWaste: function() {
      if (this.refWeight === 0) return 0;
      let percent = 100 - this.partWeight * 100 / this.refWeight;
      return (Math.round(percent * 10) / 10).toFixed(1);
    }
  }
};
</script>