<template>
  <v-navigation-drawer v-model="drawerState" app temporary>
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="light-blue--text text--accent-4"
      >
        <v-list-item to="/" exact>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>

        <v-list-item to="/loading-time" exact>
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Temps de chargement</v-list-item-title>
        </v-list-item>

        <v-list-item to="/weight-scale" exact>
          <v-list-item-icon>
            <v-icon>mdi-weight-kilogram</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pont-bascule</v-list-item-title>
        </v-list-item>

        <v-list-item to="/percent" exact>
          <v-list-item-icon>
            <v-icon>mdi-percent-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Impuretés/calibrage</v-list-item-title>
        </v-list-item>

        <v-list-item to="/insecticide" exact>
          <v-list-item-icon>
            <v-icon>mdi-bug-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Insecticide</v-list-item-title>
        </v-list-item>

        <v-list-item to="/separator" exact>
          <v-list-item-icon>
            <v-icon>mdi-image-filter-tilt-shift</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Séparateur</v-list-item-title>
        </v-list-item>

        <v-list-item to="/blending" exact>
          <v-list-item-icon>
            <v-icon>mdi-ab-testing</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mélanges</v-list-item-title>
        </v-list-item>

        <v-list-item to="/sica-webcam" exact>
          <v-list-item-icon>
            <v-icon>mdi-cctv</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Webcam SICA</v-list-item-title>
        </v-list-item>

        <v-list-item to="/barcode-config" exact>
          <v-list-item-icon>
            <v-icon>mdi-barcode</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Configuration douchette</v-list-item-title>
        </v-list-item>

        <v-list-item to="/barcode-parser" exact>
          <v-list-item-icon>
            <v-icon>mdi-barcode-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Analyseur douchette</v-list-item-title>
        </v-list-item>

        <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title>A propos</v-list-item-title>
        </v-list-item>

        <hr>

        <v-list-item to="/playground">
          <v-list-item-icon>
            <v-icon>mdi-test-tube</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tests</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useBaseStore } from '@/stores/base';

export default {
  setup() {
    const baseStore = useBaseStore();
    return {
      baseStore
    };
  },

  data: function() {
    return {
      group: null,
    };
  },

  computed: {
    drawerState: {
      get: function() { return this.baseStore.getDrawerState; },
      set: function(v) { return this.baseStore.toggleDrawerState(v); },
    }
  }
};
</script>
