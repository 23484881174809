<template>
  <v-container class="insecticide">

    <h3>Insecticide</h3>

    <h4>Calcul débit élévateur</h4>

    <p>Chronométrez le temps passé (durée en minutes/secondes) pour faire passer une quantité de grain connue (poids) devant la buse de désinsectisation.</p>

    <v-row>
      <v-col md="12">
        <v-text-field v-model.number="refWeight" label="Poids (tonne)" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-text-field v-model.number="refTimeMinutes" label="Durée (min)" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field v-model.number="refTimeSeconds" label="Durée (secondes)" type="number" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-alert type="info" dense style="margin-top: 10px;">
      Débit de l'élévateur : <strong>{{throughput || 0}}</strong> tonnes/heure.
    </v-alert>

    <h4>Calcul débit de la pompe</h4>

    <p>
      Entrez la dose en litre pour 100 tonnes (voir les "Consignes de silo" page 23 et 24)
      et le débit de travail calculé précédemment.
      Vous pouvez aussi choisir un produit dans la liste pour remplir la dose recommandée. 
    </p>

    <v-row style="margin-top: 5px;">
      <v-col md="12">
        <v-select
          v-model="selectedProduct"
          v-on:change="changeProduct"
          clearable
          dense
          :items="products"
          item-text="name"
          item-value="id"
          label="Produit"
          :menu-props="{ maxHeight: 450 }"
          persistent-hint
          single-line
          return-object
        >
          <template slot="item" slot-scope="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.usage"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-text-field v-model.number="literPerTonDose" label="Dose (L/100t)" dense hide-details="true" outlined></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field v-model.number="elevatorThroughput" label="Débit de l'élévateur (t/h)" dense hide-details="true" outlined></v-text-field>
      </v-col>
    </v-row>

    <v-alert type="info" dense style="margin-top: 10px;">
      La pompe doseuse doit être réglée à <strong>{{pumpThroughtput}}</strong> litres/heure
    </v-alert>

    <v-alert v-if="selectedProduct && selectedProduct.id >= 7" type="error" dense style="margin-top: 10px;">
      Ne jamais traiter le maïs avec le {{selectedProduct.name}} !
    </v-alert>

    <v-row justify="start" style="background-color: #eee; margin-top: 25px;">
      <v-col v-for="product in products" :key="product.id" cols="12" md="3" class="d-flex flex-column">
        <v-card v-if="selectedProduct === null || selectedProduct.id === product.id" elevation="2" class="flex d-flex flex-column">
          <v-card-title>{{product.name}}</v-card-title>
          <v-card-subtitle>{{product.usage}}</v-card-subtitle>
          <v-card-text class="flex">{{product.description}}</v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click.prevent="getFile(product.files.doc)">Document</v-btn>
            <v-btn text color="primary" @click.prevent="getFile(product.files.fds)">FDS</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      refTimeMinutes: 9,
      refTimeSeconds: 0,
      refWeight: 30,
      literPerTonDose: 4,
      elevatorThroughput: 50,

      cereals: [
        {
          id: 1,
          name: 'Blé',
          image: ''
        },
        {
          id: 2,
          name: 'Féverole',
          image: ''
        },
        {
          id: 3,
          name: 'Maïs',
          image: ''
        },
        {
          id: 4,
          name: 'Oléagineux (colza, lin, tournesol)',
          image: ''
        },
        {
          id: 5,
          name: 'Pois',
          image: ''
        },
        {
          id: 6,
          name: 'Orge',
          image: ''
        },
        {
          id: 7,
          name: 'Sorgho',
          image: ''
        }
      ],
      insects: [
        {
          id: 1,
          name: 'Alucites',
          image: ''
        },
        {
          id: 2,
          name: 'Bruches',
          image: ''
        },
        {
          id: 3,
          name: 'Capucins',
          image: ''
        },
        {
          id: 4,
          name: 'Charançons',
          image: ''
        },
        {
          id: 5,
          name: 'Mites',
          image: ''
        },
        {
          id: 6,
          name: 'Silvains',
          image: ''
        },
        {
          id: 7,
          name: 'Teignes',
          image: ''
        },
        {
          id: 8,
          name: 'Triboliums',
          image: ''
        },
      ],
      products: [
        {
          id: 1,
          name: 'K-Obiol ULV6 (préventif)',
          description: '1 application maximale par an par lot de grains. Rémanence de 6 mois à 9 mois (4.2L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les bruches sur légumineuses.',
          usage: 'Féveroles, haricots, lentilles, pois',
          dose: 4.2,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 2,
          name: 'K-Obiol ULV6 (curatif dose recommandée)',
          description: '1 application maximale par an par lot de grains. Rémanence de 6 à 9 mois (6L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les bruches sur légumineuses.',
          usage: 'Féveroles, haricots, lentilles, pois',
          dose: 6,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 3,
          name: 'K-Obiol ULV6 (curatif dose maximale)',
          description: '1 application maximale par an par lot de grains. Rémanence de 12 mois (8.4L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les bruches sur légumineuses.',
          usage: 'Féveroles, haricots, lentilles, pois',
          dose: 8.4,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 4,
          name: 'K-Obiol ULV6 (préventif)',
          description: '1 application maximale par an par lot de grains. Rémanence de 6 à 12 mois (4.2L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les alucites, charançons, silvains, triboliums...',
          usage: 'Céréales, maïs, riz',
          dose: 4.2,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 5,
          name: 'K-Obiol ULV6 (curatif dose recommandée)',
          description: '1 application maximale par an par lot de grains. Rémanence de 6 à 12 mois (6L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les alucites, charançons, silvains, triboliums...',
          usage: 'Céréales, maïs, riz',
          dose: 6,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 6,
          name: 'K-Obiol ULV6 (curatif dose maximale)',
          description: '1 application maximale par an par lot de grains. Rémanence de 12 mois et plus (8.4L/100t). 6g/L de deltaméthrine et 54g/L de pipéronyl butoxide. Uniquement sur céréales, riz, maïs et légumineuses. Cible les alucites, charançons, silvains, triboliums...',
          usage: 'Céréales, maïs, riz',
          dose: 8.4,
          files: {
            fds: 'uploads/kobiol-ulv6/fds.pdf',
            doc: 'uploads/kobiol-ulv6/fiche.pdf'
          }
        },
        {
          id: 7,
          name: 'Pirigrain SLD',
          description: '1 application maximale par lot de grains. Rémanence de 9 mois (4L/100t). 72.5g/L de pyrimiphos-méthyl. Uniquement sur blé, avoine, orge, millet et sorgho. Cible les charançons, silvains, triboliums, alucites et les teignes.',
          usage: '',
          dose: 4,
          files: {
            fds: 'uploads/pirigrain_sld/fds.pdf',
            doc: 'uploads/pirigrain_sld/fiche.pdf'
          }
        },
        {
          id: 8,
          name: 'Pirigrain SLD (pleine dose)',
          description: '1 application maximale par lot de grains. Rémanence de 12 mois (5.5L/100t). 72.5g/L de pyrimiphos-méthyl. Uniquement sur blé, avoine, orge, millet et sorgho. Cible les charançons, silvains, triboliums, alucites et les teignes.',
          usage: '',
          dose: 5.5,
          files: {
            fds: 'uploads/pirigrain_sld/fds.pdf',
            doc: 'uploads/pirigrain_sld/fiche.pdf'
          }
        },
        {
          id: 9,
          name: 'Talisma EC',
          description: '1 application maximale par lot de grains. Rémanence de 12 mois et plus (2L/100t). 80g/L de cyperméthrine et 228g/L de pipéronyl butoxyde. Uniquement sur blé, avoine, orge, seigle, sorgho, triticale et riz. Cible les charançons, capucins, blattes, triboliums, alucites, teignes et les acariens de la farine.',
          usage: 'Céréales, sorgho, riz',
          dose: 2,
          files: {
            fds: 'uploads/talisma-ec/fds.pdf',
            doc: 'uploads/talisma-ec/fiche.pdf'
          }
        }
      ],
      selectedProduct: null
    };
  },
  
  computed: {
    throughput: function() {
      let refTime = this.refTimeMinutes * 60 + this.refTimeSeconds;
      if (this.refWeight === 0) return 0;
      let elevatorThroughput = (this.refWeight * 3600) / refTime; // ton/hour
      return (Math.round(elevatorThroughput * 10) / 10).toFixed(1);
    },
    pumpThroughtput: function() {
      let literPerTon = this.literPerTonDose / 100;
      let literPerHour = literPerTon * this.elevatorThroughput;
      return (Math.round(literPerHour * 100) / 100).toFixed(2);
    }
  },

  methods: {
    changeProduct: function(selected) {
      this.literPerTonDose = selected.dose;
    },
    getFile: function(path) {
       window.open(path, "_blank");
    }
  }
};
</script>